/* eslint-disable sonarjs/no-duplicate-string */

import { GenericThemeShape } from "@twilio-paste/core/theme";

const brandThemes: Record<string, Partial<GenericThemeShape>> = {
    wrisk: {
        backgroundColors: {
            colorBackgroundPrimary: "#4F46E5",
            colorBackgroundPrimaryStrong: "#C1BEF6", // send btn disabled background
            colorBackgroundPrimaryStronger: "#4239B3",
            colorBackgroundPrimaryStrongest: "#111827",
            colorBackgroundPrimaryWeak: "#bfbcef",
            colorBackgroundPrimaryWeakest: "#F3F4F6",
            colorBackgroundStronger: "#D1D5DB" // selectable btn background hovered
        },
        borderColors: {
            colorBorderInverseStrongest: "#4239B3"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(17, 24, 39)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#111827",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#111827",
            shadowBorderInverseStrongest: "#111827"
        },
        textColors: {
            colorTextUser: "#111827"
        },
        radii: {
            borderRadius20: "4px", // all blocks border radius
            borderRadius30: "4px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fontWeights: {
            fontWeightExtrabold: 600
        },
        fonts: {
            fontFamilyDisplay: "Inter",
            fontFamilyText: "Inter"
        }
    },
    heycar: {
        backgroundColors: {
            colorBackground: "#f2f2f2", // bot messages and selectable btm background idle
            colorBackgroundStronger: "#30303059", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#303030", // chosen selected btn background
            colorBackgroundPrimaryWeakest: "#f2f2f2", // bot messages and selectable btn background disabled
            colorBackgroundPrimary: "#044bbc", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#082c73", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#A7C0E8" // send btn disabled background
        },
        borderColors: {
            colorBorderInverseStrongest: "#4239B3"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(17, 24, 39)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#303030",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#303030",
            shadowBorderInverseStrongest: "#303030"
        },
        textColors: {
            colorTextUser: "#303030"
        },
        radii: {
            borderRadius20: "0px", // all blocks border radius
            borderRadius30: "0px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fontWeights: {
            fontWeightExtrabold: 600
        },
        fonts: {
            fontFamilyDisplay: "Objektiv Mk3 Bold",
            fontFamilyText: "Objektiv Mk3"
        }
    },
    jaguar: {
        backgroundColors: {
            colorBackground: "#F0F0F0", // bot messages and selectable btm background idle
            colorBackgroundStronger: "#8D8D8D", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#141414", // chosen selected btn background
            colorBackgroundPrimaryWeakest: "#F0F0F0", // bot messages and selectable btn background disabled
            colorBackgroundPrimary: "#3C3C3B", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#000000", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#BBBBBA" // send btn disabled background
        },
        borderColors: {
            colorBorderInverseStrongest: "#141414"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(17, 24, 39)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#141414",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#141414",
            shadowBorderInverseStrongest: "#141414"
        },
        textColors: {
            colorTextUser: "#141414",
            colorText: "#141414"
        },
        radii: {
            borderRadius20: "0px", // all blocks border radius
            borderRadius30: "0px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fontWeights: {
            fontWeightExtrabold: 700,
            fontWeightSemibold: 700
        },
        fonts: {
            fontFamilyDisplay: "Proxima Nova",
            fontFamilyText: "Proxima Nova"
        },
        fontSizes: {
            fontSize50: "1.25rem"
        }
    },
    landrover: {
        backgroundColors: {
            colorBackground: "#E9ECEC", // bot messages and selectable btm background idle
            colorBackgroundStronger: "#c9cacc", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#0C121C", // chosen selected btn background
            colorBackgroundPrimaryWeakest: "#E9ECEC", // bot messages and selectable btn background disabled
            colorBackgroundPrimary: "#0C121C", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#4A4F54", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#aaacb0" // send btn disabled background
        },
        borderColors: {
            colorBorderInverseStrongest: "#0C121C"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(17, 24, 39)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#0C121C",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#0C121C",
            shadowBorderInverseStrongest: "#0C121C"
        },
        textColors: {
            colorTextUser: "#0C121C",
            colorText: "#0C121C"
        },
        radii: {
            borderRadius20: "0px", // all blocks border radius
            borderRadius30: "0px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fontWeights: {
            fontWeightExtrabold: 700,
            fontWeightSemibold: 700
        },
        fonts: {
            fontFamilyDisplay: "Land Rover Web",
            fontFamilyText: "Avenir Next"
        },
        fontSizes: {
            fontSize50: "1.25rem"
        }
    },
    volvo: {
        backgroundColors: {
            colorBackground: "#00000014", // bot messages and selectable btm background idle
            colorBackgroundStronger: "#D5D5D5", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#141414", // chosen selected btn background
            colorBackgroundPrimaryWeakest: "#00000014", // bot messages and selectable btn background disabled
            colorBackgroundPrimary: "#2A609D", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#274D7C", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#B4C7DD" // send btn disabled background
        },
        borderColors: {
            colorBorderInverseStrongest: "#141414"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(17, 24, 39)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#141414",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#141414",
            shadowBorderInverseStrongest: "#141414"
        },
        textColors: {
            colorTextUser: "#141414",
            colorText: "#141414"
        },
        radii: {
            borderRadius20: "4px", // all blocks border radius
            borderRadius30: "4px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fontWeights: {
            fontWeightExtrabold: 700,
            fontWeightSemibold: 700
        },
        fonts: {
            fontFamilyDisplay: "Volvo Novum",
            fontFamilyText: "Volvo Novum"
        },
        fontSizes: {
            fontSize50: "1.25rem"
        }
    },
    bmw: {
        backgroundColors: {
            colorBackground: "#F2F2F2", // bot messages and selectable btm background idle
            colorBackgroundPrimaryWeakest: "#F2F2F2", // bot messages and selectable btn background disabled
            colorBackgroundStronger: "#bbbbbb", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#262626", // chosen selected btn background
            colorBackgroundPrimary: "#1C69D4", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#1F54A6", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#b0cbf0" // send btn disabled background
        },
        borderColors: {
            colorBorderInverseStrongest: "#262626"
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(38, 38, 38)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#111827",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#262626",
            shadowBorderInverseStrongest: "#262626"
        },
        textColors: {
            colorTextUser: "#262626",
            colorText: "#262626"
        },
        radii: {
            borderRadius20: "0px", // all blocks border radius
            borderRadius30: "0px" // all blocks border radius
        },
        zIndices: {
            zIndex90: "9999"
        },
        fonts: {
            fontFamilyDisplay: "BMWTypeNext",
            fontFamilyText: "BMWTypeNext"
        },
        fontWeights: {
            fontWeightExtrabold: 300,
            fontWeightSemibold: 700
        },
        fontSizes: {
            fontSize50: "1.25rem"
        }
    },
    mini: {
        backgroundColors: {
            colorBackground: "#E5E5E5", // bot messages and selectable btm background idle
            colorBackgroundStronger: "#B2B2B2", // selectable btn background hovered
            colorBackgroundPrimaryStrongest: "#000", // chosen selected btn background
            colorBackgroundPrimaryWeakest: "#E5E5E5", // bot messages and selectable btn background disabled
            colorBackgroundPrimary: "#000", // main primary btn background idle
            colorBackgroundPrimaryStronger: "#525252", // main primary btn background hovered
            colorBackgroundPrimaryStrong: "#A6A6A6" // send btn disabled background
        },
        shadows: {
            shadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08);",
            shadowBorderPrimary: "0px 0px 0px 1px rgb(0, 0, 0)",
            shadowFocus: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStrong: "0px -8px 16px 0px rgba(0, 0, 0, 0.10)",
            shadowBorderPrimaryStronger: "#000",
            shadowBorderPrimaryStrongest: "0px 0px 0px 1px rgb(75, 85, 99)",
            shadowBorderInverseStrong: "#D1D5DB",
            shadowBorderInverseStronger: "#000",
            shadowBorderInverseStrongest: "#000"
        },
        borderColors: {
            colorBorderInverseStrongest: "#000"
        },
        textColors: {
            colorTextUser: "#000",
            colorText: "#000"
        },
        radii: {
            borderRadius20: "0px", // all blocks border radius
            borderRadius30: "0px" // all blocks border radius
        },
        fonts: {
            fontFamilyDisplay: "Mini Serif",
            fontFamilyText: "Mini Sans"
        },
        fontSizes: {
            fontSize50: "1.25rem"
        }
    }
};

export const createTheme = (brandName: string): Partial<GenericThemeShape> | undefined => brandThemes[brandName];
