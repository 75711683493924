import { Flex } from "@twilio-paste/core/flex";
import { Spinner } from "@twilio-paste/core/spinner";
import { Text } from "@twilio-paste/core/text";

import { loadingTextStyles } from "./styles/ConversationEnded.styles";

export const LoadingPhase = () => {
    return (
        <Flex hAlignContent="center" height="100%" vertical>
            <Flex grow vAlignContent="center">
                <Text as="p" {...loadingTextStyles}>
                    One moment we are loading a new chat session ...
                </Text>
            </Flex>
            <Flex grow>
                <Spinner title="Authorizing" decorative={false} size="sizeIcon100" color="colorTextLinkStrongest" />
            </Flex>
        </Flex>
    );
};
