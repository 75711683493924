/* eslint-disable react-hooks/exhaustive-deps */
import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { Key, KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";

import { MessageBubbleSelectable } from "./MessageBubbleSelectable";
import { EngagementPhase } from "../store/definitions";
import { changeEngagementPhase, updateMessageInputVisibility } from "../store/actions/genericActions";
import { FilePreview } from "./FilePreview";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getInnerContainerStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import { Icon } from "./Icon";
import { getMessageBubbleData } from "../selectors";

const doubleDigit = (number: number | undefined) => {
    if (!number) return "";
    return `${number < 10 ? 0 : ""}${number}`;
};

export const MessageBubble = ({
    message,
    isLast,
    focusable,
    updateFocus
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const dispatch = useDispatch();
    const [read, setRead] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, unauthorizedFormSubmitted } =
        useSelector(getMessageBubbleData);

    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    useEffect(() => {
        const displayInput = (message.attributes as any)?.displayInput || null;
        const displayUnauthorizedForm = (message.attributes as any)?.displayUnauthorizedForm || null;
        if (displayInput) {
            dispatch(updateMessageInputVisibility({ showMessageInput: displayInput }));
        }
        if (
            displayUnauthorizedForm &&
            !unauthorizedFormSubmitted &&
            !localStorage.getItem("unauthorizedFormSubmitted")
        ) {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.UnauthorizedFormPhase }));
        }
    }, []);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={media.sid} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const author = users?.find((u) => u.identity === message.author)?.friendlyName ?? message.author;
    const clickableMessage = (message.attributes as any)?.clickableMessages || null;
    const isSelectableClientResponse = (message.attributes as any)?.selectableClientResponse || null;

    if (isSelectableClientResponse) {
        return null;
    }

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space30">
                        {author === "Bot" ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#111827",
                                        padding: "4px",
                                        marginBottom: "0",
                                        lineHeight: "0",
                                        borderRadius: "3px",
                                        marginRight: "8px"
                                    }}
                                >
                                    <Icon icon="bot" size="sizeIcon10" color="colorTextWeakest" />
                                </div>
                                <Text
                                    {...authorStyles}
                                    as="p"
                                    aria-hidden
                                    style={{ textOverflow: "ellipsis" }}
                                    title={author}
                                >
                                    {author}
                                </Text>
                            </div>
                        ) : (
                            <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis" }} title="You">
                                {author === "Client" ? "You" : author ?? "Agent"}
                            </Text>
                        )}

                        <ScreenReaderOnly as="p">
                            {belongsToCurrentUser
                                ? "You sent at"
                                : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                        </ScreenReaderOnly>
                        <Text {...timeStampStyles} as="p">
                            {`${doubleDigit(message?.dateCreated?.getHours())}:${doubleDigit(
                                message?.dateCreated?.getMinutes()
                            )}`}
                        </Text>
                    </Flex>
                    <Text as="p" {...bodyStyles}>
                        {message.body ? parseMessageBody(message.body, belongsToCurrentUser) : null}
                    </Text>
                    {message.type === "media" ? renderMedia() : null}
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Read
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
            {clickableMessage && <MessageBubbleSelectable message={message} />}
        </Box>
    );
};
