import { useState, useEffect } from "react";
import { Message } from "@twilio/conversations";
import { Button } from "@twilio-paste/core/button";
import { Stack } from "@twilio-paste/core/stack";
import { useDispatch, useSelector } from "react-redux";

import { updateBotTypingStatus } from "../store/actions/genericActions";
import { getConversation } from "../selectors";

export const MessageBubbleSelectable = ({ message }: { message: Message }) => {
    const dispatch = useDispatch();
    const { conversation } = useSelector(getConversation);

    const logger = window.Twilio.getLogger("MessageInput");

    const [disabledButtons, setDisabledButtons] = useState<string[]>(() => {
        // Load disabled buttons from local storage on component mount
        const storedDisabledButtons = localStorage.getItem("disabledButtons");
        return storedDisabledButtons ? JSON.parse(storedDisabledButtons) : [];
    });
    const [clickedButtons, setClickedButtons] = useState<string[]>(() => {
        const storedDisabledButtons = localStorage.getItem("clickedButtons");
        return storedDisabledButtons ? JSON.parse(storedDisabledButtons) : [];
    });
    const send = async (selectedMessage: string, messageSid: string, clickedMessage: string) => {
        dispatch(updateBotTypingStatus({ isBotTyping: true }));

        if (!conversation) {
            logger.error("Failed sending message: no conversation found");
            return;
        }

        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage.setBody(selectedMessage).setAttributes({ selectableClientResponse: true });
        await preparedMessage.build().send();

        setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, messageSid]);
        setClickedButtons((prevClickedMessage) => [...prevClickedMessage, clickedMessage]);
    };

    const clickableMessage = (message.attributes as any)?.clickableMessages || null;
    let options: any;
    if (clickableMessage) {
        options = (message.attributes as any)?.options;
    }

    useEffect(() => {
        localStorage.setItem("disabledButtons", JSON.stringify(disabledButtons));
    }, [disabledButtons]);
    useEffect(() => {
        localStorage.setItem("clickedButtons", JSON.stringify(clickedButtons));
    }, [clickedButtons]);

    return (
        <Stack element="SELECTED_MESSAGES_CONTAINER" orientation="horizontal" spacing="space20">
            {options.map((item: any, index: number) => {
                const isDisabled = disabledButtons.includes(message.sid);
                const isClicked = clickedButtons.includes(`${message.sid}${index}`);

                return (
                    <Button
                        element="SELECTED_MESSAGE_BUTTON"
                        data-test="message-send-button"
                        variant={isClicked ? "primary" : "secondary"}
                        id={message.sid}
                        size="small"
                        type="button"
                        key={item}
                        disabled={isDisabled}
                        onClick={(event) => {
                            event.preventDefault();
                            send(item, message.sid, `${message.sid}${index}`);
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault();
                            }
                        }}
                    >
                        {item}
                    </Button>
                );
            })}
        </Stack>
    );
};
