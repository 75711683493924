import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const containerStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "colorBackgroundBody",
    padding: "space50",
    paddingTop: "space60",
    paddingBottom: "space60",
    borderTopLeftRadius: "borderRadius20",
    borderTopRightRadius: "borderRadius20",
    boxShadow: "shadow"
};

export const titleStyles: TextStyleProps = {
    color: "colorText",
    paddingLeft: "space30"
};

export const closeIconStyles: TextStyleProps = {
    marginLeft: "auto",
    display: ["block", "none"]
};
